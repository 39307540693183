import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Search, Play, Pause, Phone, User, Clock, Calendar, Mail, AlertCircle, Activity } from 'lucide-react';

const fetchCallData = async (callId, setLoading, setError, setCallData) => {
    if (!callId) return;
    setLoading(true);
    setError(null);
    try {
        const token = localStorage.getItem('auth');
        const response = await fetch('/api/call_id_data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({ callId })
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setCallData(data);
    } catch (err) {
        setError(err.message);
    } finally {
        setLoading(false);
    }
};

const CallDetailsPage = () => {
    const [callData, setCallData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [callId, setCallId] = useState('');
    const [activeTab, setActiveTab] = useState('overview');
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    const memoizedFetchCallData = useCallback(() => {
        fetchCallData(callId, setLoading, setError, setCallData);
    }, [callId]);

    useEffect(() => {
        if (callId) {
            memoizedFetchCallData();
        }
    }, [callId, memoizedFetchCallData]);

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div className="min-h-screen bg-gray-50 text-gray-900 font-sans">
            <header className="bg-white border-b border-gray-200 px-6 py-4">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <h1 className="text-lg font-semibold">Call Analytics</h1>
                    <div className="relative">
                        <input
                            type="text"
                            value={callId}
                            onChange={(e) => setCallId(e.target.value)}
                            placeholder="Enter Call ID"
                            className="pl-8 pr-4 py-1 text-sm border-b border-gray-300 focus:outline-none focus:border-blue-500"
                        />
                        <Search className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                    </div>
                </div>
            </header>

            <main className="max-w-7xl mx-auto px-6 py-8">
                {loading && <div className="text-center text-gray-500 text-sm">Loading...</div>}
                {error && <div className="text-center text-red-500 text-sm mb-4">{error}</div>}
                {callData && (
                    <div className="bg-white rounded-lg shadow">
                        <div className="border-b border-gray-200">
                            <nav className="flex -mb-px">
                                <TabButton active={activeTab === 'overview'} onClick={() => setActiveTab('overview')} icon={<Activity size={16} />}>Overview</TabButton>
                                <TabButton active={activeTab === 'details'} onClick={() => setActiveTab('details')} icon={<Phone size={16} />}>Call Details</TabButton>
                                <TabButton active={activeTab === 'logs'} onClick={() => setActiveTab('logs')} icon={<AlertCircle size={16} />}>Logs</TabButton>
                                <TabButton active={activeTab === 'events'} onClick={() => setActiveTab('events')} icon={<Clock size={16} />}>Events</TabButton>
                                <TabButton active={activeTab === 'messages'} onClick={() => setActiveTab('messages')} icon={<Mail size={16} />}>Agent Messages</TabButton>
                            </nav>
                        </div>

                        <div className="p-6">
                            {activeTab === 'overview' && <OverviewTab callData={callData} isPlaying={isPlaying} togglePlayPause={togglePlayPause} />}
                            {activeTab === 'details' && <DetailsTab callData={callData} />}
                            {activeTab === 'logs' && <LogsTab logs={callData.logs} />}
                            {activeTab === 'events' && <EventsTab events={callData.events} />}
                            {activeTab === 'messages' && <MessagesTab messages={callData.agentMessages} />}
                        </div>
                    </div>
                )}
            </main>
            <audio ref={audioRef} src={callData?.call?.recording_url} onEnded={() => setIsPlaying(false)} />
        </div>
    );
};

const TabButton = ({ children, active, onClick, icon }) => (
    <button
        className={`flex items-center px-4 py-2 text-sm font-medium leading-5 ${active
                ? 'border-b-2 border-blue-500 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300'
            }`}
        onClick={onClick}
    >
        {icon && <span className="mr-2">{icon}</span>}
        {children}
    </button>
);

const OverviewTab = ({ callData, isPlaying, togglePlayPause }) => {
    const recentActivity = [...callData.logs, ...callData.events]
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 5);

    return (
        <div>
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">Call {callData.call.id}</h2>
                <div className="flex items-center space-x-4">
                    <span className={`px-2 py-1 text-xs font-medium ${callData.call.completed ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                        } rounded`}>
                        {callData.call.completed ? 'Completed' : 'In Progress'}
                    </span>
                    <button
                        onClick={togglePlayPause}
                        className="text-blue-600 hover:text-blue-700"
                    >
                        {isPlaying ? <Pause size={20} /> : <Play size={20} />}
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-6">
                <InfoCard icon={<User size={16} />} label="Caller" value={`${callData.user.first_name} ${callData.user.last_name}`} />
                <InfoCard icon={<Mail size={16} />} label="Email" value={callData.user.email} />
                <InfoCard icon={<Clock size={16} />} label="Duration" value={`${callData.call.call_length}s`} />
                <InfoCard icon={<Calendar size={16} />} label="Date" value={new Date(callData.call.created_at).toLocaleDateString()} />
            </div>
            <div className="text-sm text-gray-600">
                <h3 className="font-medium mb-2">Recent Activity</h3>
                <ul className="space-y-2">
                    {recentActivity.map((item, index) => (
                        <li key={index} className="flex justify-between">
                            <span>{item.text || item.message}</span>
                            <span className="text-gray-400">{new Date(item.created_at).toLocaleTimeString()}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const DetailsTab = ({ callData }) => (
    <div className="space-y-6">
        <div>
            <h3 className="text-lg font-medium mb-2">Call Information</h3>
            <div className="grid grid-cols-2 gap-4">
                <InfoItem label="From" value={callData.call.from} />
                <InfoItem label="To" value={callData.call.to} />
                <InfoItem label="Duration" value={`${callData.call.call_length} seconds`} />
                <InfoItem label="Status" value={callData.call.completed ? 'Completed' : 'In Progress'} />
            </div>
        </div>
        <div>
            <h3 className="text-lg font-medium mb-2">User Information</h3>
            <div className="grid grid-cols-2 gap-4">
                <InfoItem label="Name" value={`${callData.user.first_name} ${callData.user.last_name}`} />
                <InfoItem label="Email" value={callData.user.email} />
            </div>
        </div>
    </div>
);

const LogsTab = ({ logs }) => {
    const sortedLogs = logs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return (
        <div className="space-y-4">
            {sortedLogs.map((log) => (
                <LogItem key={log.id} log={log} />
            ))}
        </div>
    );
};

const EventsTab = ({ events }) => {
    const sortedEvents = events.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {sortedEvents.map((event, index) => (
                        <tr key={event.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {new Date(event.created_at).toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {event.category}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                                {event.message}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const MessagesTab = ({ messages }) => {
    const sortedMessages = messages.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return (
        <div className="space-y-4">
            {sortedMessages.map((message) => (
                <AgentMessageItem key={message.id} message={message} />
            ))}
        </div>
    );
};

const InfoCard = ({ icon, label, value }) => (
    <div className="flex items-center space-x-3 bg-gray-50 p-4 rounded-lg">
        <div className="text-gray-400">{icon}</div>
        <div>
            <div className="text-xs text-gray-500">{label}</div>
            <div className="font-medium">{value}</div>
        </div>
    </div>
);

const InfoItem = ({ label, value }) => (
    <div>
        <dt className="text-sm font-medium text-gray-500">{label}</dt>
        <dd className="mt-1 text-sm text-gray-900">{value}</dd>
    </div>
);

const LogItem = ({ log }) => (
    <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="flex justify-between items-center">
            <div className="font-medium">{log.status}</div>
            <div className="text-xs text-gray-500">{new Date(log.created_at).toLocaleString()}</div>
        </div>
        <div className="mt-1 text-sm text-gray-700">{log.text}</div>
    </div>
);

const AgentMessageItem = ({ message }) => (
    <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="flex justify-between items-center">
            <div className="font-medium">{message.model}</div>
            <div className="text-xs text-gray-500">{new Date(message.created_at).toLocaleString()}</div>
        </div>
        <pre className="mt-2 text-xs text-gray-600 whitespace-pre-wrap overflow-x-auto bg-gray-50 p-2 rounded">
            {JSON.stringify(message.messages, null, 2)}
        </pre>
    </div>
);

export default CallDetailsPage;