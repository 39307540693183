import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, AreaChart, Area, BarChart, Bar, XAxis, YAxis, Tooltip, LineChart, Line } from 'recharts';
import { motion } from 'framer-motion';

const MAX_RETRIES = 1;
const RETRY_DELAY = 2000; // 2 seconds
const BATCH_SIZE = 50; // Adjust this value based on your needs

const EnterpriseUsageDashboard = () => {
    const [userCallData, setUserCallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchWithRetry = async (url, options, retries = 0) => {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (e) {
            if (retries < MAX_RETRIES) {
                console.log(`Retrying fetch (${retries + 1}/${MAX_RETRIES})...`);
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                return fetchWithRetry(url, options, retries + 1);
            }
            throw e;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('auth');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': token
                };

                // Fetch users with retry
                const usersData = await fetchWithRetry('/api/get_users', {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({ excludeTemplates: [1, 5, 4, 6, 7] }),
                });

                const users = usersData.users;

                // Prepare date range
                const now = new Date();
                const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
                const startDate = oneWeekAgo.toISOString();
                const endDate = now.toISOString();

                // Split users into batches
                const userBatches = [];
                for (let i = 0; i < users.length; i += BATCH_SIZE) {
                    userBatches.push(users.slice(i, i + BATCH_SIZE));
                }

                // Fetch call data for all batches in parallel
                const batchResults = await Promise.all(
                    userBatches.map(batch =>
                        fetchWithRetry('/api/enterprise_usage', {
                            method: 'POST',
                            headers,
                            body: JSON.stringify({
                                userIds: batch.map(user => user.id),
                                startDate,
                                endDate,
                            }),
                        })
                    )
                );

                // Combine and process all batch results
                const allCallStats = batchResults.flatMap(result => result.callStats);

                const formattedData = users.map(user => {
                    const userCalls = allCallStats.find(stat => stat.user_id === user.id);
                    const dailyData = userCalls ? Object.entries(userCalls.daily_calls).map(([date, calls]) => ({ date, calls: parseInt(calls) })) : [];
                    const totalCalls = dailyData.reduce((sum, day) => sum + day.calls, 0);
                    const recentCalls = dailyData.slice(-3).reduce((sum, day) => sum + day.calls, 0);
                    const earlierCalls = dailyData.slice(-7, -3).reduce((sum, day) => sum + day.calls, 0);
                    const callChangePercent = earlierCalls === 0 ? (recentCalls > 0 ? 100 : 0) : ((recentCalls - earlierCalls) / earlierCalls) * 100;

                    return {
                        id: user.id,
                        name: `${user.first_name} ${user.last_name}`,
                        totalCalls,
                        dailyData,
                        callChangePercent
                    };
                });

                // Validate data
                if (formattedData.some(user => isNaN(user.totalCalls) || isNaN(user.callChangePercent))) {
                    throw new Error('Invalid data received');
                }

                formattedData.sort((a, b) => b.totalCalls - a.totalCalls);
                setUserCallData(formattedData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <motion.div
                className="w-16 h-16 border-4 border-blue-500 rounded-full"
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            />
        </div>
    );
    if (error) return <div className="text-center p-4 text-red-500 text-sm">Error: {error}</div>;

    const totalCalls = userCallData.reduce((sum, user) => sum + user.totalCalls, 0);
    const activeUsers = userCallData.filter(user => user.totalCalls > 0).length;
    const topUsers = userCallData.slice(0, 5);

    return (
        <div className="bg-gray-100 text-gray-900 min-h-screen p-8 font-sans">
            <h1 className="text-4xl font-bold mb-8">Enterprise Usage Insights</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                <MetricCard title="Total Calls" value={totalCalls.toLocaleString()} />
                <MetricCard title="Active Users" value={`${activeUsers} / ${userCallData.length}`} />
                <MetricCard title="Avg Calls/User" value={(totalCalls / activeUsers).toFixed(2)} />
                <MetricCard title="Date Range" value="Last 7 Days" />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                <Card title="Call Volume Trend">
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={aggregateCallData(userCallData)}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="calls" stroke="#3182ce" fill="#ebf8ff" />
                        </AreaChart>
                    </ResponsiveContainer>
                </Card>

                <Card title="Top Users by Call Volume">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={topUsers} layout="vertical">
                            <XAxis type="number" />
                            <YAxis dataKey="name" type="category" width={150} />
                            <Tooltip />
                            <Bar dataKey="totalCalls" fill="#4299e1" />
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            </div>

            <Card title="User Activity Details">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="p-2 text-left">User</th>
                                <th className="p-2 text-right">Total Calls</th>
                                <th className="p-2 text-right">Change</th>
                                <th className="p-2 text-center">Trend</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userCallData.map(user => (
                                <tr key={user.id} className="border-b border-gray-200 hover:bg-gray-50">
                                    <td className="p-2">{user.name}</td>
                                    <td className="p-2 text-right">{user.totalCalls}</td>
                                    <td className="p-2 text-right">
                                        <span className={getChangeColor(user.callChangePercent)}>
                                            {user.callChangePercent.toFixed(2)}%
                                        </span>
                                    </td>
                                    <td className="p-2">
                                        <ResponsiveContainer width="100%" height={30}>
                                            <LineChart data={user.dailyData}>
                                                <Line type="monotone" dataKey="calls" stroke="#3182ce" strokeWidth={2} dot={false} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </div>
    );
};

const MetricCard = ({ title, value }) => (
    <motion.div
        className="bg-white p-6 rounded-lg shadow-lg"
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300, damping: 10 }}
    >
        <h3 className="text-lg font-semibold text-gray-600 mb-2">{title}</h3>
        <p className="text-3xl font-bold text-blue-600">{value}</p>
    </motion.div>
);

const Card = ({ title, children }) => (
    <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        {children}
    </div>
);

const getChangeColor = (percent) => {
    if (percent > 0) return 'text-green-600';
    if (percent < 0) return 'text-red-600';
    return 'text-gray-600';
};

const aggregateCallData = (userData) => {
    const aggregatedData = {};
    userData.forEach(user => {
        user.dailyData.forEach(day => {
            if (aggregatedData[day.date]) {
                aggregatedData[day.date] += day.calls;
            } else {
                aggregatedData[day.date] = day.calls;
            }
        });
    });
    return Object.entries(aggregatedData).map(([date, calls]) => ({ date, calls }));
};

export default EnterpriseUsageDashboard;