import React, { useState } from 'react';
import EnterpriseUsageDashboard from './AdminPage';
import CallDetailsPage from './CallIdPage';
import UpdateAccount from './UpdateUser';

// Enterprise Usage Component


// Sidebar Component
const Sidebar = ({ setActiveComponent }) => (
    <div className="bg-gray-800 text-white w-64 h-full p-4">
        <h1 className="text-2xl font-bold mb-6">Dashboard</h1>
        <nav>
            <button
                onClick={() => setActiveComponent('enterpriseUsage')}
                className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
            >
                Enterprise Usage
            </button>
            <button
                onClick={() => setActiveComponent('callDetails')}
                className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
            >
                Call Details
            </button>
            <button
                onClick={() => setActiveComponent('userUpdate')}
                className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
            >
                Update Account
            </button>
        </nav>
    </div>
);

// Main Dashboard Component
const Dashboard = () => {
    const [activeComponent, setActiveComponent] = useState('enterpriseUsage');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'enterpriseUsage':
                return <EnterpriseUsageDashboard />;
            case 'callDetails':
                return <CallDetailsPage />;
            case 'userUpdate':
                return <UpdateAccount />;
            default:
                return <EnterpriseUsageDashboard />;
        }
    };

    return (
        <div className="flex h-screen bg-gray-100">
            <Sidebar setActiveComponent={setActiveComponent} />
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
                {renderComponent()}
            </main>
        </div>
    );
};

export default Dashboard;