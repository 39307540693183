import React, { useState } from 'react';
import axios from 'axios';
import { Search, User, Phone, Mail, CheckCircle, AlertCircle, Save, Loader } from 'lucide-react';

const UserLookupAndUpdate = () => {
    const [lookupData, setLookupData] = useState({ type: 'phone', value: '' });
    const [userData, setUserData] = useState(null);
    const [editedData, setEditedData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const handleLookupChange = (e) => {
        const { name, value } = e.target;
        setLookupData(prev => ({ ...prev, [name]: value }));
    };

    const handleLookupSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setUserData(null);
        setEditedData({});

        try {
            const response = await axios.post('/api/enterprise/get_user', {
                [lookupData.type === 'id' ? 'userId' : lookupData.type]: lookupData.value
            }, {
                headers: { 'Authorization': localStorage.getItem('auth') }
            });

            setUserData(response.data);
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to fetch user data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (field, value) => {
        setEditedData(prev => ({ ...prev, [field]: value }));
    };

    const handleUpdateSubmit = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage('');

        try {
            for (const [field, value] of Object.entries(editedData)) {
                let processedValue = value;
                if (field === 'billing_template' || field === 'rate_limit_template') {
                    processedValue = parseInt(value);
                } else if (field === 'current_balance') {
                    processedValue = parseFloat(value);
                }

                await axios.post('/api/enterprise/update_user', {
                    id: userData.user.id,
                    field,
                    value: processedValue
                }, {
                    headers: { 'Authorization': localStorage.getItem('auth') }
                });
            }

            const updatedUserData = await axios.post('/api/enterprise/get_user', {
                userId: userData.user.id
            }, {
                headers: { 'Authorization': localStorage.getItem('auth') }
            });

            setUserData(updatedUserData.data);
            setEditedData({});
            setSuccessMessage('User updated successfully!');
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to update user. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const renderField = (label, value, field) => {
        const formattedLabel = label.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        let inputElement;

        if (field === 'billing_template') {
            inputElement = (
                <select
                    value={editedData[field] || value.id}
                    onChange={(e) => handleInputChange(field, e.target.value)}
                    className="w-full px-3 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {userData.allBillingTemplates.map(template => (
                        <option key={template.id} value={template.id}>{template.description}</option>
                    ))}
                </select>
            );
        } else if (field === 'rate_limit_template') {
            inputElement = (
                <select
                    value={editedData[field] || value.id}
                    onChange={(e) => handleInputChange(field, e.target.value)}
                    className="w-full px-3 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {userData.allRateLimitTemplates.map(template => (
                        <option key={template.id} value={template.id}>{template.description}</option>
                    ))}
                </select>
            );
        } else {
            inputElement = (
                <input
                    type="text"
                    value={editedData[field] !== undefined ? editedData[field] : value}
                    onChange={(e) => handleInputChange(field, e.target.value)}
                    className="w-full px-3 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            );
        }

        return (
            <div key={field} className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">{formattedLabel}</label>
                {inputElement}
            </div>
        );
    };

    const renderUserData = () => {
        if (!userData) return null;

        const { user } = userData;

        const fieldsToRender = [
            { label: 'First Name', field: 'first_name', value: user.first_name },
            { label: 'Last Name', field: 'last_name', value: user.last_name },
            { label: 'Email', field: 'email', value: user.email },
            { label: 'Billing Template', field: 'billing_template', value: user.billing_template },
            { label: 'Rate Limit Template', field: 'rate_limit_template', value: user.rate_limit_template },
            { label: 'Current Balance', field: 'current_balance', value: user.billing.current_balance }
        ];

        return (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-900">User Information</h3>
                </div>
                <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                    {fieldsToRender.map(({ label, field, value }) => renderField(label, value, field))}
                </div>
                {Object.keys(editedData).length > 0 && (
                    <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end">
                        <button
                            onClick={handleUpdateSubmit}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            disabled={loading}
                        >
                            {loading ? 'Updating...' : 'Update User'}
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const LoadingAnimation = () => (
        <div className="flex justify-center items-center h-64">
            <div className="relative w-24 h-24">
                <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-200 rounded-full"></div>
                <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 rounded-full animate-spin" style={{ borderTopColor: 'transparent', animationDuration: '1s' }}></div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Loader size={24} className="text-blue-500 animate-pulse" />
                </div>
            </div>
        </div>
    );

    return (
        <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">User Lookup and Update</h1>
            {error && (
                <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-4 flex items-center" role="alert">
                    <AlertCircle size={20} className="text-red-400 mr-2" />
                    <p className="text-sm text-red-700">{error}</p>
                </div>
            )}
            {successMessage && (
                <div className="mb-4 bg-green-50 border-l-4 border-green-400 p-4 flex items-center" role="alert">
                    <CheckCircle size={20} className="text-green-400 mr-2" />
                    <p className="text-sm text-green-700">{successMessage}</p>
                </div>
            )}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
                <div className="px-6 py-4">
                    <form onSubmit={handleLookupSubmit} className="flex space-x-4">
                        <div className="w-1/3">
                            <select
                                name="type"
                                value={lookupData.type}
                                onChange={handleLookupChange}
                                className="w-full px-3 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="phone">Phone Number</option>
                                <option value="email">Email</option>
                                <option value="id">User ID</option>
                            </select>
                        </div>
                        <div className="w-2/3 relative">
                            <input
                                type={lookupData.type === 'email' ? 'email' : 'text'}
                                name="value"
                                value={lookupData.value}
                                onChange={handleLookupChange}
                                className="w-full pl-10 pr-12 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder={lookupData.type === 'phone' ? 'Enter phone number' : lookupData.type === 'email' ? 'Enter email' : 'Enter user ID'}
                                required
                            />
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                {lookupData.type === 'phone' ? <Phone size={16} className="text-gray-400" /> :
                                    lookupData.type === 'email' ? <Mail size={16} className="text-gray-400" /> :
                                        <User size={16} className="text-gray-400" />}
                            </span>
                            <button
                                type="submit"
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-white bg-blue-500 rounded-r-md hover:bg-blue-600 focus:outline-none"
                                disabled={loading}
                            >
                                <Search size={16} />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {loading ? (
                <LoadingAnimation />
            ) : (
                renderUserData()
            )}
        </div>
    );
};

export default UserLookupAndUpdate;