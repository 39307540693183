import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import HomePage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';
import EnterpriseUsageDashboard from './pages/AdminPage';
import CallDetailsPage from './pages/CallIdPage';
import Dashboard from './pages/MainPage';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <Link className="App-link" to="/about">
          Learn React
        </Link>
      </header>
    </div>
  );
}

function About() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>About Page</h1>
        <Link className="App-link" to="/">
          Go back to Home
        </Link>
      </header>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<Dashboard />} />
        {/* <Route path="/" element={<CallDetailsPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
